import axios from 'axios'
import React,{useState,useEffect} from 'react'
import { BaseUrl } from '../Constants/BaseUrl'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from 'react-router-dom';
function AddUser(props) {
const [Mobile, setMobile] = useState('')
const [Name, setName] = useState('')
const [userTypeId, setuserTypeId] = useState("default")
const [userTypeData, setuserTypeData] = useState([])
const [isLoading, setIsLoading] = useState(false);
useEffect(() => {
  axios.get(BaseUrl+`/User/GetAllUserTypes`)
      .then(response => {
        const filteredData = response.data.filter(userType => userType.id !== 1);
      setuserTypeData(filteredData);
      })
      .catch(error => {
        console.log(error);
      })
}, [])
const Clear=()=>{
  setMobile('')
  setName('')
  setuserTypeId("default")
}

const AddUser=()=>{
  const ApiToken = JSON.parse(localStorage.getItem('ChungathToken'));
  const userDetails={Mobile,UserType:userTypeId,Name}
  console.log(userDetails)
  if(Mobile.length===10&&userTypeId!=='default'&&Name.length>0){
    setIsLoading(true)
    axios.post(BaseUrl+`/Admin/CreateUser`,userDetails, {
      headers: {
        Authorization: `Bearer ${ApiToken}`, 
      },
    })
    .then(response => {
      // console.log(response.data)
      if (response.data.Status === 200) {
        toast.success(response.data.Message, {
          position: 'top-center',
        });    
        Clear()
      }else{
        toast.error(response.data.Message, {
          position: 'top-center',
        });
      }
    }).catch(function (error) {
        toast.error('API token is not valid. Please log in again.', {
          position: 'top-center',
        })
        props.Logout()
      console.log(error)
    })
    .finally(() => {
      setIsLoading(false);
    }); 
  }else{
    if(userTypeId==='default'){
      alert('Please select user type')
    }else if(Mobile.length!==10){
      if (Mobile.length === 0) {
        alert('Please Enter Number');
      } else if (Mobile.length < 10) {
        alert('Number should be at least 10 digits long');
      } else if (Mobile.length > 10) {
        alert('Number should not exceed 10 digits');
      }   
    }else if(Name.length===0){
      alert('please enter name')
    }
  }

}

  return (
<div className="login-box">
  <div className='AddUser'>
  {/* <h2>Issue QR</h2> */}
  <div className="user-box">
      <input type="number" name="" placeholder='Enter user Mobile Number' required="" className='no-spinners' value={Mobile} onChange={(e) => setMobile(e.target.value)}/>
      <label>Mobile No:</label>
    </div>
  <div className="user-box">
      <input type="text" placeholder='Enter User Name' value={Name} onChange={(e) => setName(e.target.value)}/>
      <label>Name:</label>
    </div>
    <div className="user-box">
    <select name="customerName" id="customerName" required className='UserTypeSelect' value={userTypeId} onChange={(e) => setuserTypeId(e.target.value)}>
    <option value="default" disabled>Select User Type</option>
    {userTypeData.map(userType => (
              <option key={userType.id} value={userType.id}>
                {userType.UserType}
              </option>
            ))}
  </select>
      <label className='UserTypeLabel'>Type:</label>
    </div>
    <div className='row  d-flex'>
      <div className='col-4'>
      <button  className='ClearButton' onClick={Clear}>
      <i class="fa-solid fa-arrows-rotate" style={{ marginRight: '8px' }}></i>
     Clear
    </button>
      </div>
      <div className='col-8'>
    <button onClick={AddUser} disabled={isLoading}>
    <i class="fa-solid fa-user-plus" style={{ marginRight: '8px' }}></i>
    {isLoading ? 'Loading...' : 'Add'}
    </button>
    </div>
    </div>
    </div>
</div>

  )
}

export default AddUser