import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { BaseUrl } from '../Constants/BaseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaginationSample from './PaginationSample';
function RepeatLog(props) {
  const [RepeatLogData, setRepeatLogData] = useState([])
  const getYesterdayDate = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday.toISOString().split('T')[0];
  };
  const [FromDate, setFromDate] = useState(new Date().toISOString().split('T')[0])
  const [ToDate, setToDate] = useState(new Date().toISOString().split('T')[0])
  useEffect(() => {
    const ApiToken = JSON.parse(localStorage.getItem('ChungathToken'));
      const Data={From:FromDate,To:ToDate}
        axios
        .post(BaseUrl + `/Admin/GetIssueLog`, Data, {
          headers: {
            Authorization: `Bearer ${ApiToken}`, 
          },
        })
        .then((response) => {
          // console.log(response.data);
          setRepeatLogData(response.data)
          setFilteredData(response.data)
        })
        .catch(function (error) {
            toast.error('API token is not valid. Please log in again.', {
              position: 'top-center',
            })
            props.Logout()
          console.log(error)
        })
  }, [FromDate,ToDate])
      //Serch 
      const [FilteredData, setFilteredData] = useState([])
      const [searchQuery, setSearchQuery] = useState('')
      const handleSearch = (e) => {
        setCurrentPage(1)
        const searchValue = e.target.value.toLowerCase();
        setSearchQuery(searchValue);
    
        const filteredData = RepeatLogData.filter((item) => {
          for (const key in item) {
            const value = item[key];
            if (typeof value === 'string' && value.toLowerCase().includes(searchValue)) {
              return true;
            } else if (typeof value === 'number' && value.toString().includes(searchValue)) {
              return true;
            }
          }
          return false;
        });
        setFilteredData(filteredData);
      };
      // const dataToDisplay = searchQuery.length > 0 ? FilteredData : RepeatLogData;
        //Pagination
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataToDisplay = searchQuery.length > 0 ? FilteredData: RepeatLogData.slice(startIndex, endIndex);
  return (
    <> 
      <div className='row mb-4'>
 <div className='col-lg-5 col-6'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>Search:</label>
   <input
     type='search'
     className='form-control'
     style={{ width: '100%',borderRadius:0 }}
     value={searchQuery}
     onChange={handleSearch}
   />
 </div>
    </div>   
     <div className='row mb-4'>
 <div className='col-lg-5 col-6'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>From:</label>
   <input
     type='date'
     className='form-control'
     style={{ width: '100%',borderRadius:0 }}
     value={FromDate}
     onChange={(e) => setFromDate(e.target.value)}
   />
 </div>
 <div className='col-lg-5 col-6'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>To:</label>
   <input
     type='date'
     className='form-control'
     style={{ width: '100%',borderRadius:0}}
     value={ToDate}
     onChange={(e) => setToDate(e.target.value)}
   />
 </div>
    </div>   
 <div className="col-12" style={{ background: '#ffffff', color: '#282c34' }}>
 <table className="table table-bordered table-responsive" style={{ border: '0px solid #d2ac67' }}>
  <thead style={{ background: '#048444', color: 'White' }}>
    <tr>
      <th className="text-center">Sl.no</th>
      <th className="text-center">Executive Name</th>
      <th className="text-center">Number</th>
      <th className="text-center">Repeat Count</th>
    </tr>
  </thead>
  {dataToDisplay.length === 0 ? (
    <tbody>
      <tr>
        <td colSpan="4" className="text-center">
        No data available for {FromDate} to {ToDate}
        </td>
      </tr>
    </tbody>
  ) : (
    <tbody>
      {dataToDisplay.map((data, index) => (
        <tr className="text-center" key={index}>
          <td data-label="slno">{startIndex + index + 1}</td>
          <td data-label="Name">{data.Executive}</td>
          <td data-label="Number">{data.Mobile}</td>
          <td data-label="Count">{data.Count}</td>
        </tr>
      ))}
    </tbody>
  )}
</table>

{RepeatLogData.length>20&&<PaginationSample
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={searchQuery.length > 0 ? FilteredData.length : RepeatLogData.length}
          onPageClick={handlePageClick}
        />}
 </div>
 </>
  )
}

export default RepeatLog