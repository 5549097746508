import React, { useState, useEffect, useRef } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import logo from '../Images/ChugathLogoCrop.png';
import AddUserPng from '../Images/add-user.png';
import '../Css/AdminPage.css';
import AddUser from '../AdminPage Components/AddUser';
import ConsolidatedReportPng from '../Images/DeatiledReport.png';
import DetailedReportPng from '../Images/report.png';
import RepeatLogPng from '../Images/file.png';
import ConsolidatedReport from '../AdminPage Components/ConsolidatedReport';
import DetailedReport from '../AdminPage Components/DetailedReport';
import RepeatLog from '../AdminPage Components/RepeatLog';
import { useNavigate } from 'react-router-dom';
import UsersListPng from '../Images/UsersList.png';
import UsersList from '../AdminPage Components/UsersList';

function AdminPage() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('A');
  const [startY, setStartY] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshThreshold = 100; // Adjust the threshold as needed
  const headerWrapperRef = useRef(null);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const logout = () => {
    localStorage.removeItem('ChungathToken');
    localStorage.removeItem('UserType');
    navigate('/');
  };

  const handleTouchStart = (event) => {
    setStartY(event.touches[0].clientY);
  };

  const handleTouchEnd = (event) => {
    const deltaY = event.changedTouches[0].clientY - startY;

    if (
      deltaY > refreshThreshold &&
      deltaY > 0 &&
      headerWrapperRef.current &&
      headerWrapperRef.current.contains(event.target) // Check if the touch event is within the header-wrapper
    ) {
      // Ask for confirmation before refreshing
      const shouldRefresh = window.confirm('Do you want to refresh?');
      if (shouldRefresh) {
        setIsRefreshing(true);
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [startY, refreshThreshold]);

  function LogoutButton() {
    const confirmMessage = 'Are you sure you want to logout?';

    if (window.confirm(confirmMessage)) {
      localStorage.removeItem('ChungathToken');
      localStorage.removeItem('UserType');
      navigate('/');
    }
  }
  return (
    <div className="bodybg cv-bodypadding">
    <div className="header-wrapper" ref={headerWrapperRef}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-5">
            <img src={logo} className="cv-reg-taglahlogo" alt="SaveDate" />
          </div>
          <div className="col-lg-6 col-md-6 col-7">
            <button className="notification-bell" onClick={LogoutButton}>
            <i class="fa-solid fa-right-from-bracket" style={{ color:'red' }}></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  
    <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
      <div className="savedate-sidebar">
        <Nav className="flex-column " fill variant="pills" id="v-pills-tab" aria-orientation="vertical">
          <Nav.Link eventKey="A" style={{ textAlign: 'left' }}>
            <span style={{ backgroundImage: `url(${AddUserPng})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></span>
            <h6>Add User</h6>
          </Nav.Link>
          <Nav.Link eventKey="E" style={{ textAlign: 'left' }}>
            <span style={{ backgroundImage: `url(${UsersListPng})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></span>
            <h6>Users List</h6>
          </Nav.Link>
          <Nav.Link eventKey="B" style={{ textAlign: 'left' }}>
            <span className="groom" style={{  backgroundImage: `url(${ConsolidatedReportPng})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></span>
            <h6>Consolidated Report</h6>
          </Nav.Link>
          <Nav.Link eventKey="C" style={{ textAlign: 'left' }}>
            <span className="appearance" style={{ backgroundImage: `url(${DetailedReportPng})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></span>
            <h6>Detailed Report</h6>
          </Nav.Link>
          <Nav.Link eventKey="D" style={{ textAlign: 'left' }}>
            <span className="appearance" style={{backgroundImage: `url(${RepeatLogPng})`,  backgroundSize: 'cover', backgroundPosition: 'center' }}></span>
            <h6>Repeat Log</h6>
          </Nav.Link>
         
        </Nav>
      </div>
      <div className="container-fluid">
        <Tab.Content id="v-pills-tabContent">
          <Tab.Pane eventKey="A" style={{ marginTop: '60px' }}>
            <AddUser Logout={logout}/>
          </Tab.Pane>
          <Tab.Pane eventKey="B" style={{ marginTop: '60px' }}>
            <ConsolidatedReport Logout={logout}/>
          </Tab.Pane>
          <Tab.Pane eventKey="C" style={{ marginTop: '60px' }}>
            <DetailedReport Logout={logout}/>
          </Tab.Pane>
          <Tab.Pane eventKey="D" style={{ marginTop: '90px' }}>
            <RepeatLog Logout={logout}/>
          </Tab.Pane>
          <Tab.Pane eventKey="E" style={{ marginTop: '90px' }}>
            <UsersList Logout={logout}/>
          </Tab.Pane>
        </Tab.Content>
      </div>
    </Tab.Container>
  </div>
  
  )
}

export default AdminPage