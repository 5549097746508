import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { BaseUrl } from '../Constants/BaseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Css/ResponsiveTable.css'
import PaginationSample from './PaginationSample';
import LoadingModal from '../components/LoadingModal';
function DetailedReport(props) {
  const ApiToken = JSON.parse(localStorage.getItem('ChungathToken'));
  const [Type, setType] = useState(2)
  const [DetailedReportData, setDetailedReportData] = useState([])
  const getYesterdayDate = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday.toISOString().split('T')[0];
  };
  const [FromDate, setFromDate] = useState(new Date().toISOString().split('T')[0])
  const [ToDate, setToDate] = useState(new Date().toISOString().split('T')[0])
  useEffect(() => {
      const Data={Type:Type,From:FromDate,To:ToDate}
      showLoadingModalOpen()
        axios
        .post(BaseUrl + `/Admin/GetDetailedReport`, Data, {
          headers: {
            Authorization: `Bearer ${ApiToken}`, 
          },
        })
        .then((response) => {
          // console.log(response.data);
          setDetailedReportData(response.data)
          setFilteredData(response.data)
        })
        .catch(function (error) {
        
            toast.error('API token is not valid. Please log in again.', {
              position: 'top-center',
            })
            props.Logout()
          console.log(error)
        }).finally(() => {
          closeLoadingModal()
        });
  }, [FromDate,ToDate,Type])
  //Date conversion
  const convertTime = (inputTime) => {
    const date = new Date(inputTime);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1

    // Format hours in 12-hour format and add 'AM' or 'PM'
    let formattedHours = (hours % 12) || 12;
    const amPm = hours >= 12 ? 'PM' : 'AM';

    // Add leading zeros to minutes and month if necessary
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

    // Create the final output string
    const outputString = `${formattedHours}.${formattedMinutes}${amPm}, ${day}/${formattedMonth}/${date.getFullYear()}`;

    return outputString;
  };
  //Resend
  const ResendOTP=(Mobile)=>{
    const confirmMessage = "Are you sure you want Resend?";
    if(window.confirm(confirmMessage)){
      axios.get(BaseUrl + `/Admin/ResendCoupon?Mobile=${Mobile}`, {
        headers: {
          Authorization: `Bearer ${ApiToken}`,
        }
      })
      .then(response => {
        // console.log(response.data)
        if (response.data.Status === 200) {
          toast.success(response.data.Message, {
            position: 'top-center'
          });
        } else {
          alert(response.data.Message);
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }
    //Serch 
    const [FilteredData, setFilteredData] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [Search, setSearch] = useState('')
    const handleSearch = () => {
      setCurrentPage(1)
      const searchValue = Search.toLowerCase();
      setSearchQuery(searchValue);
  
      const filteredData = DetailedReportData.filter((item) => {
        for (const key in item) {
          const value = item[key];
          if (typeof value === 'string' && value.toLowerCase().includes(searchValue)) {
            return true;
          } else if (typeof value === 'number' && value.toString().includes(searchValue)) {
            return true;
          }
        }
        return false;
      });
      setFilteredData(filteredData);
      // console.log(filteredData)
    };
    // const dataToDisplay = searchQuery.length > 0 ? FilteredData : DetailedReportData;
     //Pagination
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataToDisplay = searchQuery.length > 0 ? FilteredData: DetailedReportData.slice(startIndex, endIndex);
    //Loading Modal
    const [showLoadingModal, setShowLoadingModal] = useState(false);

    const showLoadingModalOpen = () => {
      setShowLoadingModal(true);
    };
  
    const closeLoadingModal = () => {
      setShowLoadingModal(false);
    };
    //Enter Key
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
      handleSearch()
    }};
  return (
    <> 
    <LoadingModal showLoadingModal={showLoadingModal} closeLoadingModal={closeLoadingModal}/>
     <div className='row mb-4'>
 <div className='col-lg-5 col-3'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>Select:</label>
   <select name="customerName" id="customerName" value={Type} onChange={(e)=>{setCurrentPage(1); setType(e.target.value)}} required className='form-control'
    style={{ width: '100%',borderRadius:0,color:'#000' }}>
    <option value={2}>Issue</option>
    <option value={3}>Redeem</option>
  </select>
 </div>
 <div className='col-lg-5 col-6'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>Search:</label>
   <input
     type='search'
     className='form-control'
     style={{ width: '100%',borderRadius:0 }}
     value={Search}
     onChange={(e)=>setSearch(e.target.value)}
     onKeyPress={handleKeyPress}
   />
 </div>
 <div className='col-lg-2 col-3'>
    <button style={{marginTop:'24px',width:'50%',height:'39px',background:'#d2ac67',border:'none'}} onClick={handleSearch}>
    <i class="fa-solid fa-magnifying-glass" ></i></button>
 </div>
    </div>   
    <div className='row mb-4'>
 <div className='col-lg-5 col-6'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>From:</label>
   <input
     type='date'
     className='form-control'
     style={{ width: '100%',borderRadius:0 }}
     value={FromDate}
     onChange={(e) => setFromDate(e.target.value)}
   />
 </div>
 <div className='col-lg-5 col-6'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>To:</label>
   <input
     type='date'
     className='form-control'
     style={{ width: '100%',borderRadius:0}}
     value={ToDate}
     onChange={(e) => setToDate(e.target.value)}
   />
 </div>
    </div>
 <div className="col-12 my-table-container">
 <table className="table table-bordered table-responsive">
  <thead style={{ background: '#048444', color: 'White' }}>
    <tr>
      <th className="text-center">Sl.no</th>
      <th className="text-center">Executive Name</th>
      <th className="text-center">Customer Name</th>
      <th className="text-center">Customer Number</th>
      {Type==3&&<th className="text-center">SecretNo</th>}
      <th className="text-center">House Name</th>
      <th className="text-center">Location</th>
      <th className="text-center">Date</th>
     { Type==2&&<th className="text-center">Status</th>}
    </tr>
  </thead>
  {DetailedReportData.length === 0 ? (
    <tbody>
      <tr>
        <td colSpan={Type == 3 ? 8 : 8} className="text-center">
        No data available for {FromDate} to {ToDate}
        </td>
      </tr>
    </tbody>
  ) : (
    <tbody>
      {dataToDisplay.map((data, index) => (
        <tr className="text-center" key={index}>
          <td data-label="slno">{startIndex + index + 1}</td>
          <td data-label="Executive Name">{data.Executive}</td>
          <td data-label="Customer Name">{data.Name}</td>
          <td data-label="Customer Number">{data.Mobile}</td>
      {Type==3&&<td data-label="SecretNo">{data.SecretNo}</td>}
          <td data-label="House Name">{data.HouseName}</td>
          <td data-label="Location">{data.Location}</td>
          <td data-label="Date">{convertTime(data.SysTime)}</td>
          {Type==2&&<td data-label="Status">
          {data.Consumed === 1 ? (
        <p>Coupon Claimed!</p>
      ) : (
        <button
          style={{
            background: '#d2ac67',
            width: '100%',
            fontSize: '15px',
            fontWeight: 700,
            border: 0,
            minHeight: '34px',
          }}
          onClick={() => ResendOTP(data.Mobile)}
        >
          <i className="far fa-paper-plane" style={{ marginRight: '8px' }}></i>
          Resend QR
        </button>)}
          </td>}
        </tr>
      ))}
    </tbody>
  )}
</table>
{DetailedReportData.length>20&&<PaginationSample
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={searchQuery.length > 0 ? FilteredData.length : DetailedReportData.length}
          onPageClick={handlePageClick}
        />}

 </div>
 </>
  )
}

export default DetailedReport