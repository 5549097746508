import React, { useState, useRef } from 'react';
import '../Css/OTPInput.css';

const OTPInput = (props) => {
  const [otp, setOTP] = useState('');
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const value = e.target.value;

    // Ensure the value has at most 1 digit
    if (/^\d{0,1}$/.test(value)) {
      // Update the OTP value in local state
      setOTP((prevOTP) => {
        const newOTP = prevOTP.split('');
        newOTP[index] = value;
        return newOTP.join('');
      });

      // Focus on the next input when a digit is entered
      if (value && index < inputsRef.current.length - 1) {
        inputsRef.current[index + 1].focus();
      }

      // Update the OTP value in parent component's state
      if (props.setOTP) {
        props.setOTP((prevOTP) => {
          const newOTP = prevOTP.split('');
          newOTP[index] = value;
          return newOTP.join('');
        });
      }
    }
  };

  const handleKeyDown = (e, index) => {
    // Handle the backspace key to navigate to the previous input
    if (e.key === 'Backspace') {
      if (index > 0) {
        inputsRef.current[index - 1].focus();
      }

      // Clear the current input's value
      setOTP((prevOTP) => {
        const newOTP = prevOTP.split('');
        newOTP[index] = '';
        return newOTP.join('');
      });
    }
  };

  return (
    <div className="otp-input-container">
      {Array.from({ length: 6 }, (_, index) => (
        <input
          key={index}
          type="number"
          inputMode="numeric"
          maxLength="1"
          value={otp[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(ref) => (inputsRef.current[index] = ref)}
          autoFocus={index === 0}
        />
      ))}
    </div>
  );
};

export default OTPInput;
