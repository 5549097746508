import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import '../Css/PaginationSample.css'; // Import your CSS file for styling

function PaginationSample({ currentPage, itemsPerPage, totalItems, onPageClick }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePaginationClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageClick(page);
    }
  };

  const renderPaginationItems = () => {
    const paginationItems = [];

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - 2 && i <= currentPage + 2)) {
        paginationItems.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => handlePaginationClick(i)}
          >
            {i}
          </Pagination.Item>
        );
      } else if (
        (i === currentPage - 3 && currentPage - 4 > 1) ||
        (i === currentPage + 3 && currentPage + 4 < totalPages)
      ) {
        paginationItems.push(
          <Pagination.Ellipsis
            key={i}
            onClick={() => handlePaginationClick(i)}
          />
        );
      }
    }

    return paginationItems;
  };

  return (
    <div className="pagination-container">
      <Pagination>
        <Pagination.Prev
          onClick={() => handlePaginationClick(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {'<'}
        </Pagination.Prev>
        {renderPaginationItems()}
        <Pagination.Next
          onClick={() => handlePaginationClick(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {'>'}
        </Pagination.Next>
      </Pagination>
    </div>
  );
}

export default PaginationSample;
