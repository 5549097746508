import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { Modal } from 'react-bootstrap';
import { BaseUrl } from '../Constants/BaseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function EditUserModal(props) {
  const ApiToken = JSON.parse(localStorage.getItem('ChungathToken'));
  useEffect(() => {
    if(props.userDetails){
      setMobile(props.userDetails.Phone)
    setName(props.userDetails.Name)
    setUserId(props.userDetails.Id)
    }
    }, [props.userDetails])
  const [Name, setName] = useState('');
  const [Mobile, setMobile] = useState('')
  const [UserId, setUserId] = useState(0)
  const AddUser=()=>{
    const Data={Name,Mobile,UserId}
    console.log(Data)
        axios
        .post(BaseUrl + `/Admin/UpdateUser`, Data, {
          headers: {
            Authorization: `Bearer ${ApiToken}`, 
          },
        })
        .then((response) => {
          // console.log(response.data)
          if (response.data.Status === 200) {
            toast.success(response.data.Message, {
              position: 'top-center'
            });
            props.ToggleFinished()
            props.Close()
          } else {
            alert(response.data.Message);
          }
        })
        .catch((error) => {
          console.log(error)
        });
  }
  return (
    <Modal show={props.Show} onHide={props.Close} id="writefeedback" tabIndex="-1" centered >
    <Modal.Header closeButton>
      Edit
    </Modal.Header>
    <Modal.Body >
     <div class="row">
       <div class="col-lg-12">
           <div class="input-item">
            <label>Name</label>
               <input type="text" class="form-control" value={Name} onChange={(e) => setName(e.target.value)}/>
                 </div>
                 </div>
                <div class="col-lg-12">
                 <div class="input-item">
             <label>Number</label>
               <input type="tel" class="form-control" value={Mobile} onChange={(e) => setMobile(e.target.value)}/>
                 </div>
           </div>
   </div>
    </Modal.Body>
    <Modal.Footer>
    <button type="button" class="addnew-btn" onClick={AddUser}>
    <i class="fa-solid fa-circle-check" style={{padding:'8px'}}></i>
      Save</button>
      </Modal.Footer>
  </Modal>
  )
}

export default EditUserModal