import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { BaseUrl } from '../Constants/BaseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaginationSample from './PaginationSample';
function ConsolidatedReport(props) {
  const [ConsolidatedData, setConsolidatedData] = useState([])
  const [Type, setType] = useState(2)
  const [TotalCount, setTotalCount] = useState(0)
  const [FromDate, setFromDate] = useState(new Date().toISOString().split('T')[0])
  const [ToDate, setToDate] = useState(new Date().toISOString().split('T')[0])
  useEffect(() => {
    const ApiToken = JSON.parse(localStorage.getItem('ChungathToken'));

      const Data={Type:Type,From:FromDate,To:ToDate}
        axios
        .post(BaseUrl + `/Admin/GetConsolidatedReport`, Data, {
          headers: {
            Authorization: `Bearer ${ApiToken}`, 
          },
        })
        .then((response) => {
          setConsolidatedData(response.data)
          setFilteredData(response.data)
          const totalSum = response.data.reduce((sum, item) => sum + item.Count, 0);
            setTotalCount(totalSum)
        })
        .catch(function (error) {
            toast.error('API token is not valid. Please log in again.', {
              position: 'top-center',
            })
            props.Logout()
          console.log(error)
        })
  }, [Type,FromDate,ToDate])
        //Serch 
        const [FilteredData, setFilteredData] = useState([])
        const [searchQuery, setSearchQuery] = useState('')
        const handleSearch = (e) => {
          setCurrentPage(1)
          const searchValue = e.target.value.toLowerCase();
          setSearchQuery(searchValue);
      
          const filteredData = ConsolidatedData.filter((item) => {
            for (const key in item) {
              const value = item[key];
              if (typeof value === 'string' && value.toLowerCase().includes(searchValue)) {
                return true;
              } else if (typeof value === 'number' && value.toString().includes(searchValue)) {
                return true;
              }
            }
            return false;
          });
          setFilteredData(filteredData);
        };
        // const dataToDisplay = searchQuery.length > 0 ? FilteredData : ConsolidatedData;
         //Pagination
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataToDisplay = searchQuery.length > 0 ? FilteredData:ConsolidatedData.slice(startIndex, endIndex);
  return (
    <>     
     <div className='row mb-4'>
     <div className='col-lg-4 col-4'>
  <label style={{ fontWeight: '700' ,color:'#d2ac67' }}>Select</label>
  <select name="customerName" id="customerName" value={Type} onChange={(e)=>setType(e.target.value)} required className='form-control'
    style={{ width: '100%',borderRadius:0,color:'#000' }}>
    <option value={2}>Issue</option>
    <option value={3}>Redeem</option>
  </select>
 
</div>
<div className='col-lg-4 col-4'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>From:</label>
   <input
     type='date'
     className='form-control'
     style={{ width: '100%',borderRadius:0 }}
     value={FromDate}
     onChange={(e) => setFromDate(e.target.value)}
   />
 </div>
 <div className='col-lg-4 col-4'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>To:</label>
   <input
     type='date'
     className='form-control'
     style={{ width: '100%',borderRadius:0}}
     value={ToDate}
     onChange={(e) => setToDate(e.target.value)}
   />
 </div>
    </div>  
       <div className='row mb-4'>
       <div className='col-lg-5 col-6'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>Search:</label>
   <input
     type='search'
     className='form-control'
     style={{ width: '100%',borderRadius:0 }}
     value={searchQuery}
     onChange={handleSearch}
   />
 </div>
 
    <div className='col-lg-5 col-6'>
      <label style={{ fontWeight: '700',color:'#d2ac67' }}>Total Count:</label>
      <div style={{ background: 'white', border: '1px solid grey', width: '75%', height: '35px', maxWidth: '165px' }}>
     <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',fontSize:'20px',fontWeight:'700',color:'green' }}>{TotalCount}</label>
     </div>
    </div>
  </div>
    <div className="col-12" style={{ background: '#ffffff', color: '#282c34' }}>
    {ConsolidatedData.length === 0 ? (
  <p>No data available</p>
) : (
  <table className="table table-bordered table-responsive" style={{ border: '0px solid #d2ac67' }}>
    <thead style={{ background: '#048444', color: 'White' }}>
      <tr>
        <th className="text-center">Sl.no</th>
        <th className="text-center">Executive Name</th>
        <th className="text-center">Count</th>
      </tr>
    </thead>
    <tbody>
      {dataToDisplay.map((data, index) => (
        <tr className="text-center" key={index}>
          <td data-label="slno">{startIndex + index + 1}</td>
          <td data-label="Name">{data.Executive}</td>
          <td data-label="Count">{data.Count}</td>
        </tr>
      ))}
    </tbody>
  </table>
)}
{ConsolidatedData.length>20&&<PaginationSample
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={searchQuery.length > 0 ? FilteredData.length : ConsolidatedData.length}
          onPageClick={handlePageClick}
        />}
    </div>
    </>
  )
}

export default ConsolidatedReport