import React,{useState,useEffect} from 'react';
import './App.css';
import LoginPage from './Pages/LoginPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import IssueQRpage from './Pages/IssueQRpage';
import RadeemQRpage from './Pages/RadeemQRpage';
import AdminPage from './Pages/AdminPage';
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage/>} /> 
          <Route path="/issueQR" element={<IssueQRpage/>} /> 
          <Route path="/Redeem" element={<RadeemQRpage/>} /> 
          <Route path="/admin" element={<AdminPage/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
