import React,{useState,useEffect} from 'react';
import logo from '../Images/ChugathLogoCrop.png';
import '../Css/LoginPage.css';
import { BaseUrl } from '../Constants/BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
function LoginPage() {
  const navigate=useNavigate()
  const [Phone, setPhone] = useState('')
  const [IsLogin, setIsLogin] = useState(true)
  const [OTP, setOTP] = useState('')
  const [IsOtp, setIsOtp] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('ChungathToken'));
    const UserType = JSON.parse(localStorage.getItem('UserType'));
    if (token&&UserType) {
      console.log(token.UserType)
      if (UserType === 1) {
        navigate('/admin');
      } else if (UserType === 2) {
        navigate('/issueQR');
      } else if (UserType === 3) {
        navigate('/Redeem');
      }
    }
  }, []);
  const handleToggleControl = (val) => {
    if (val === 1) {
      setIsLogin(true);
      setIsOtp(false);
    } else if (val === 2) {
      setIsLogin(false);
      setIsOtp(true);
    }
  }
  const GenerateOtp=()=>{
    if (Phone.length>0) {
      setIsLoading(true);
      axios.get(BaseUrl+`/User/Login?username=${Phone}`)
      .then(response => {
        // console.log(response.data)
        if(response.data.Status===200){
          toast.success(response.data.Message, {
            position: 'top-center' // Set the position to top-center
          });
          handleToggleControl(2)
        }else{
          alert(response.data.Message)
        }
      })
      .catch(error => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      }); 
    } else {
      alert('Please Enter Phone number')
    }
  }
  const SubmitOTP=()=>{
    if (OTP.length>0) {
      setIsLoading(true);
      axios.get(BaseUrl+`/User/VerifyOtp?Mobile=${Phone}&Otp=${OTP}`)
      .then(response => {
        // console.log(response.data)
        if(response.data.Status===200){
          toast.success(response.data.Message, {
            position: 'top-center' // Set the position to top-center
          });
          localStorage.setItem('ChungathToken', JSON.stringify(response.data.Token))
          localStorage.setItem('UserType', JSON.stringify(response.data.UserType))
         if(response.data.UserType===1){
          navigate('/admin')
         }else if(response.data.UserType==2){
          navigate('/issueQR')
         }else if(response.data.UserType==3){
          navigate('/Redeem')
         } else {
              alert('User not found');
            }
        }else{
          alert(response.data.Message)
        }
      })
      .catch(error => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      });
    
    } else {
      alert('Please enter OTP')
    }
  }
  // Refresh
const [startY, setStartY] = useState(0);
const [refreshThreshold, setRefreshThreshold] = useState(200);
const [isRefreshing, setIsRefreshing] = useState(false);

useEffect(() => {
  const handleTouchStart = (event) => {
    setStartY(event.touches[0].clientY);
  };

  const handleTouchEnd = (event) => {
    const deltaY = event.changedTouches[0].clientY - startY;
    if (deltaY > refreshThreshold) {
      // Ask for confirmation before refreshing
      const shouldRefresh = window.confirm("Do you want to refresh?");
      if (shouldRefresh) {
        setIsRefreshing(true);
        window.location.reload();
      }
    }
  };

  document.addEventListener('touchstart', handleTouchStart);
  document.addEventListener('touchend', handleTouchEnd);

  return () => {
    document.removeEventListener('touchstart', handleTouchStart);
    document.removeEventListener('touchend', handleTouchEnd);
  };
}, [refreshThreshold, startY]);
  return (
    <div className="login-page">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-4 col-md-6">
          <div className="login-wrapper">
            <span>
              <img src={logo} alt="Chugath Logo" />
            </span>
            <h3>Welcome</h3>
           {IsLogin&&(
            <>
           <h6>Please enter your Phone number</h6>
              <div className="form-group">
                <i className="fas fa-lock"></i>
                <input
                  type="number"
                  placeholder="Phone"
                  value={Phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="no-spinners"
                />
              </div>
              <button onClick={GenerateOtp} disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Continue'}
              </button>
              </>
              )}
            {IsOtp&&(
            <>
           <h6>Please enter OTP</h6>
           <div className="form-group">
                  <i className="fas fa-lock"></i>
                  <input
                    type="number"
                    placeholder="Enter OTP"
                    value={OTP}
                    onChange={(e) => setOTP(e.target.value)}
                    className="no-spinners"
                  />
                </div>
              <button onClick={SubmitOTP} disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Submit'}
              </button>
              </>
              )}
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default LoginPage;
