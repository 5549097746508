import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BaseUrl } from '../Constants/BaseUrl';
import { Network } from '@capacitor/network';
function NotifictaionModal(props) {
  const offlineData = JSON.parse(localStorage.getItem('offlineData')) || [];
  const ApiToken = JSON.parse(localStorage.getItem('ChungathToken'));
  const [IsLoading, setIsLoading] = useState(false);
  async function CheckInternetStatus() {
    try {
      const status = await Network.getStatus();
      return status.connected;
    } catch (error) {
      console.error('Error checking network status:', error);
      return false; // Assuming no internet connection in case of an error
    }
  }
  const sendQR = async (data,index) => {
    const isConnected = await CheckInternetStatus();
    if(isConnected){
     const IssueData = { Name: data.Name, Mobile: data.Mobile,CouponId:1, HouseName:data.HouseName, Location:data.Location}
     setIsLoading(true);
     axios.post(BaseUrl + `/Issuer/IssueCoupon`, IssueData, {
       headers: {
         Authorization: `Bearer ${ApiToken}`, 
       },
     })
     .then((response) => {
      //  console.log(response.data);
       if (response.data.Status === 200) {
         toast.success(response.data.Message, {
           position: 'top-center',
         });
         const updatedOfflineData = [...offlineData];
         updatedOfflineData.splice(index, 1);
         localStorage.setItem('offlineData', JSON.stringify(updatedOfflineData));
       } else {
         alert(response.data.Message);
       }
     }).catch(function (error) {
         toast.error('API token is not valid. Please log in again.', {
           position: 'top-center',
         })
         props.logout()
       console.log(error)
     })
    .finally(() => {
       setIsLoading(false);
     })
    }else{
     alert('You are currently offline. Please connect to the internet and try again.');
    }
   }

   const deleteRow = (index) => {
    // Prompt the user for confirmation
    const confirmed = window.confirm("Are you sure you want to delete this details?")
    if (confirmed) {
      const updatedOfflineData = [...offlineData];
      updatedOfflineData.splice(index, 1);
      localStorage.setItem('offlineData', JSON.stringify(updatedOfflineData));
    }
  };
  

  return (
    <Modal show={props.Show} onHide={props.handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: 'center', width: '100%' }} className='NotifictionHeading'>Offline data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row my-table-container'>
          <div className="col-12 ">
            <table className="table table-bordered table-responsive ">
              <thead style={{ background: "rgba(62, 148, 140, 1)" }}>
                <tr>
                  <th>Phone</th>
                  <th>Name</th>
                  <th>House Name</th>
                  <th>Location</th>
                  <th>IssueQR</th>
                  <th>Delete</th> {/* Add the Delete column header */}
                </tr>
              </thead>
              <tbody>
                {offlineData.map((data, index) => (
                  <tr key={index}>
                    <td data-label="Phone">{data.Mobile}</td>
                    <td data-label="Name">{data.Name}</td>
                    <td data-label="House Name">{data.HouseName}</td>
                    <td data-label="Location">{data.Location}</td>
                    <td data-label="IssueQR">
                      <button style={{ background: '#d2ac67' }} onClick={() => sendQR(data, index)} disabled={IsLoading}>
                        <i className="fa-sharp fa-solid fa-qrcode" style={{ marginRight: '8px' }}></i>
                        {IsLoading ? 'Sending QR...' : 'Send QR'}
                      </button>
                    </td>
                    <td data-label="Delete">
                      <button style={{ background: 'red' }} onClick={() => deleteRow(index)}>
                      <i class="fa-solid fa-trash" style={{ marginRight: '8px' }}></i>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default NotifictaionModal;
