import React, { useState,useCallback,useEffect} from 'react';
import '../Css/RadeemQR.css'
import QrReader from "react-qr-reader";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from '../Constants/BaseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OTPInput from '../components/OTPInput';
function RadeemQRpage() {
  const ApiToken = JSON.parse(localStorage.getItem('ChungathToken'));
  const navigate=useNavigate()
  const [Phone, setPhone] = useState('');
//Logout
const logout =()=>{
  localStorage.removeItem('ChungathToken');
  localStorage.removeItem('UserType');
  navigate('/')
}
///Scanner 
const [selected, setSelected] = useState("environment");
const [startScan, setStartScan] = useState(false);
const [loadingScan, setLoadingScan] = useState(false);
const [data, setData] = useState("");
const [MemberId, setMemberId] = useState(0)

const handleScan = async (scanData) => {
  setLoadingScan(true);
  if (scanData && scanData !== "") {
    setData(scanData);
    setStartScan(false);
    setLoadingScan(false);
    const ScanedData={Mobile:'',CouponCode:scanData,CouponId:1}
    axios.post(BaseUrl + `/Redeem/Send_Otp`, ScanedData, {
      headers: {
        Authorization: `Bearer ${ApiToken}`, 
      },
    })
    .then((response) => {
      // console.log(response.data);
      if (response.data.Status === 200) {
        toast.success(response.data.Message, {
          position: 'top-center',
        });
        setMemberId(response.data.MemberId)
      } else {
        alert(response.data.Message)
      }
    })
    .catch(function (error) {
        toast.error('API token is not valid. Please log in again.', {
          position: 'top-center',
        })
        logout()
      console.log(error)
    })
  }
};
const handleError = (err) => {
  console.error(err);
};
///Send OTP using Phone
const sendOTPMobile=()=>{
  if(Phone.length===10){
    const ScanedData={Mobile:Phone,CouponCode:'',CouponId:1}
      axios
      .post(BaseUrl + `/Redeem/Send_Otp`, ScanedData, {
        headers: {
          Authorization: `Bearer ${ApiToken}`, 
        },
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.Status === 200) {
          toast.success(response.data.Message, {
            position: 'top-center',
          });
          setMemberId(response.data.MemberId)
        } else {
          alert(response.data.Message);
        }
      })
      .catch(function (error) {
          toast.error('API token is not valid. Please log in again.', {
            position: 'top-center',
          })
          logout()
        console.log(error)
      })
  }else{
    if (Phone.length === 0) {
      alert('Please Enter Customer WhatsApp number');
    } else if (Phone.length < 10) {
      alert('Customer WhatsApp number should be at least 10 digits long');
    } else if (Phone.length > 10) {
      alert('Customer WhatsApp number should not exceed 10 digits');
    }   
  }
}
//RadeemQR code
const [OTP, setOTP] = useState('')
const [IsFinished, setIsFinished] = useState(false)
function Refresh(){
  setMemberId(0)
  setPhone('')
  setData('')
}
const FinshRedeem=()=>{
  setIsFinished(!IsFinished)
}
const RadeemQR = () => {
  // const MemberIdString=MemberId.toString()
  // console.log(typeof OTP)
  if (OTP&&OTP.length>0) {
    axios.get(BaseUrl + `/Redeem/Verify_Coupon_Using_Otp?Otp=${OTP}&MemberId=${MemberId}`, {
      headers: {
        Authorization: `Bearer ${ApiToken}`,
      }
    })
    .then(response => {
      if (response.data.Status === 200) {
        toast.success(response.data.Message, {
          position: 'top-center'
        });
        // console.log(response.data)
        FinshRedeem()
        Refresh()
      } else {
        alert(response.data.Message);
      }
    })
    .catch(error => {
      console.log(error);
    })
  } else {
    alert('Please Enter OTP')
  }
};
//Last Fetch
  //Date conversion
  const convertTime = (inputTime) => {
    const date = new Date(inputTime);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1

    // Format hours in 12-hour format and add 'AM' or 'PM'
    let formattedHours = (hours % 12) || 12;
    const amPm = hours >= 12 ? 'PM' : 'AM';

    // Add leading zeros to minutes and month if necessary
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

    // Create the final output string
    const outputString = `${formattedHours}.${formattedMinutes}${amPm}, ${day}/${formattedMonth}/${date.getFullYear()}`;

    return outputString;
  };
const [LastFetchData, setLastFetchData] = useState({})
useEffect(() => {
  axios.get(BaseUrl + `/Redeem/GetLastFetch`, {
    headers: {
      Authorization: `Bearer ${ApiToken}`,
    }
  })
    .then((response) => {
      // console.log(response.data);
      setLastFetchData(response.data)
    })
    .catch(function (error) {
        toast.error('API token is not valid. Please log in again.', {
          position: 'top-center',
        })
        logout()
      console.log(error)
    })
}, [IsFinished])

function LogoutButton() {
  const confirmMessage = "Are you sure you want to logout?"

  if (window.confirm(confirmMessage)) {
  localStorage.removeItem('ChungathToken');
  localStorage.removeItem('UserType');
  navigate('/')
  }
}
//Clear 
const Clear=()=>{
  const confirmMessage = "Are you sure you want to Clear?"

  if (window.confirm(confirmMessage)) {
  setOTP('')
  setPhone('')
  setMemberId(0)
  setData('')
  }
}
// Refresh
const [startY, setStartY] = useState(0);
const [refreshThreshold, setRefreshThreshold] = useState(200);
const [isRefreshing, setIsRefreshing] = useState(false);

useEffect(() => {
  const handleTouchStart = (event) => {
    setStartY(event.touches[0].clientY);
  };

  const handleTouchEnd = (event) => {
    const deltaY = event.changedTouches[0].clientY - startY;
    if (deltaY > refreshThreshold) {
      // Ask for confirmation before refreshing
      const shouldRefresh = window.confirm("Do you want to refresh?");
      if (shouldRefresh) {
        setIsRefreshing(true);
        window.location.reload();
      }
    }
  };

  document.addEventListener('touchstart', handleTouchStart);
  document.addEventListener('touchend', handleTouchEnd);

  return () => {
    document.removeEventListener('touchstart', handleTouchStart);
    document.removeEventListener('touchend', handleTouchEnd);
  };
}, [refreshThreshold, startY]);
  return (
    <div className="login-box" style={{background:'rgba(0,0,0,.5)'}}>
         <div className="logout-button" style={{ position: 'absolute', top: 0, right: 0 }}>
  <button style={{ color: '#dc1818',background:'rgba(0,0,0,.5)'}} onClick={LogoutButton}>
    <i class="fa-solid fa-right-from-bracket" style={{ marginRight: '8px' }}></i>
    
  </button>
</div>
      <h2>Redeem</h2>
   {LastFetchData&& <div className='row RadeemBox mb-3'>
      <div className='detailsHeading'>
  <h6 className='heading'>Last read details</h6>
       </div>
      <div className='detailsDate'>
       <h5>{convertTime(LastFetchData.ConsumedTime)}</h5>
      </div>
    <div className='detailsName'>
    <h5>{LastFetchData.MemberName}</h5>
    </div>
  <div className='detailsHouseName'>
    <p>{LastFetchData.HouseName}, {LastFetchData.Location}</p>
  </div>
  <div className='detailsPhoneNumber'>
    <h2>{LastFetchData.MemberMobile}</h2>
  </div>
  <div className='detailsCode'>
    <h1>{LastFetchData.SecretNo}</h1>
  </div>
      </div>}
      <div className='row RadeemBox' style={{border:'0px'}}>
        <div className='col-12 mt-2'>
        {startScan ? (
            <QrReader
              facingMode={selected}
              delay={1000}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%' }}
            />
          ) : null}
        <button onClick={() => {
          setStartScan(!startScan);
        }}>
        <i className={startScan?'fa-solid fa-ban' :'fa-sharp fa-solid fa-qrcode'} style={{ marginRight: '8px' }}></i>
        {startScan ? "Stop Scan" : " Scan QR"}
      </button>
        </div>
        {!startScan&&<div className='col-12 d-flex mt-4 mb-2'>
          <div className='col-8 me-2'>
            <input
              type="number"
              placeholder="Phone"
              value={Phone}
              onChange={(e) => setPhone(e.target.value)}
              className="no-spinners form-control"
            />
          </div>
          <div className='col-4'>
            <button className="btn btn-primary sendOTP" onClick={sendOTPMobile}>Send OTP</button>
          </div>
        </div>}
      </div>

     {MemberId!==0&&<div className='row RadeemBox mt-3' style={{border:'0px'}}>
        <div className='col-12 mt-2'>
         <h2 className='OTPheading'>Enter OTP</h2>
        </div>
        <div className='col-12 mt-2'>
        <OTPInput setOTP={setOTP}/>
        </div>
        <div className='col-12 d-flex mt-4 mb-2'>
          <div className='col-4 me-2 '>
          <button className="btn btn-primary ClearRadeem" onClick={Clear}>Clear Scan</button>
          </div>
          <div className='col-8'>
            <button className="btn btn-primary RadeemButton" onClick={RadeemQR}>Redeem</button>
          </div>
        </div>
      </div>}
    </div>
  );
}

export default RadeemQRpage;
