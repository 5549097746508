import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { BaseUrl } from '../Constants/BaseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditUserModal from './EditUserModal';
import PaginationSample from './PaginationSample';

function UsersList() {
  const ApiToken = JSON.parse(localStorage.getItem('ChungathToken'));
    const [Type, setType] = useState(0)
    const [UsersData, setUsersData] = useState([])
    const [IsFinished, setIsFinished] = useState(true)
    const ToggleFinished=()=>{
      setIsFinished(!IsFinished)
    }
    useEffect(() => {
      axios.get(BaseUrl + `/Admin/GetAllUserDetails?UserType=${Type}`, {
        headers: {
          Authorization: `Bearer ${ApiToken}`, 
        }
      })
      .then(response => {
        setUsersData(response.data)
        setFilteredData(response.data);
      })
      .catch(error => {
        console.log(error);
      });
    }, [Type,IsFinished]);
    
    const Deactivate=(Id)=>{
      axios.get(BaseUrl + `/Admin/Enable_Disable_User?UserId=${Id}`, {
        headers: {
          Authorization: `Bearer ${ApiToken}`, 
        }
      })
      .then(response => {
        if (response.data.Status === 200) {
          toast.success(response.data.Message, {
            position: 'top-center'
          });
          ToggleFinished()
        } else {
          alert(response.data.Message);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  //Edit Modal
  const [ShowEditModal, setShowEditModal] = useState(false)
  const CloseModal=()=>{
    setShowEditModal(false)
  }
  const ShowModal=()=>{
    setShowEditModal(true)
  }
  const [UserDetails, setUserDetails] = useState({
    Id:'',
    Name:'',
    Phone:''
  })
  const Edit = (Id, Name, Phone) => {
    setUserDetails({
      Id: Id,
      Name: Name,
      Phone: Phone,
    });

    setShowEditModal(true)
  };
  //Serch 
  const [FilteredData, setFilteredData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const handleSearch = (e) => {
    setCurrentPage(1)
    const searchValue = e.target.value.toLowerCase();
    setSearchQuery(searchValue);

    const filteredData = UsersData.filter((item) => {
      for (const key in item) {
        const value = item[key];
        if (typeof value === 'string' && value.toLowerCase().includes(searchValue)) {
          return true;
        } else if (typeof value === 'number' && value.toString().includes(searchValue)) {
          return true;
        }
      }
      return false;
    });
    setFilteredData(filteredData);
  };
  // const dataToDisplay = searchQuery.length > 0 ? FilteredData : UsersData;
  //Pagination
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataToDisplay = searchQuery.length > 0 ? FilteredData: UsersData.slice(startIndex, endIndex);
  return (
    <> 
        <div className='row mb-4'>
 <div className='col-lg-5 col-6'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>Select:</label>
   <select name="customerName" id="customerName" value={Type} onChange={(e)=>{setCurrentPage(1); setType(e.target.value)}} required className='form-control'
    style={{ width: '100%',borderRadius:0,color:'#000' }}>
   <option value={0}>All</option>
   <option value={1}>Admin</option>
    <option value={2}>Issue</option>
    <option value={3}>Redeem</option>
  </select>
 </div>
 <div className='col-lg-5 col-6'>
   <label style={{ fontWeight: '700',color:'#d2ac67' }}>Search:</label>
   <input
     type='search'
     className='form-control'
     style={{ width: '100%',borderRadius:0 }}
     value={searchQuery}
     onChange={handleSearch}
   />
 </div>
    </div>   
 <div className="col-12 my-table-container">
 <table className="table table-bordered table-responsive">
  <thead style={{ background: '#048444', color: 'White' }}>
    <tr>
      <th className="text-center">Sl.no</th>
      <th className="text-center">Executive Name</th>
      <th className="text-center">Mobile Number</th>
      <th className="text-center">Actions</th>
    </tr>
  </thead>
    <tbody>
    {dataToDisplay.map((data, index) => (
  <tr className="text-center" key={index}>
    <td data-label="slno">{startIndex + index + 1}</td>
    <td data-label="Executive Name">{data.Name}</td>
    <td data-label="Mobile Number">{data.Mobile}</td>
    <td data-label="Actions">
      <div className="row">
        <div className="col-6">
          <button
            style={{
              background: '#d2ac67',
              width: '100%',
              fontSize: '11px',
              fontWeight: 700,
              border: 0,
              minHeight: '34px',
            }}
            onClick={()=>Edit(data.Id,data.Name,data.Mobile)}
          >
            Edit
          </button>
        </div>
        <div className="col-6">
          <button
            style={{
              background: '#d2ac67',
              width: '100%',
              fontSize: '11px',
              fontWeight: 700,
              border: 0,
              minHeight: '34px',
              color: 'white',
              backgroundColor: data.Status === 0 ? 'red' : 'green', // Set background color based on status
            }}
            onClick={()=>Deactivate(data.Id)}
          >
            {data.Status === 0 ? 'Deactivate' : 'Activate'} {/* Set button text based on status */}
          </button>
        </div>
      </div>
    </td>
  </tr>
))}

    </tbody>
</table>
{UsersData.length>20&&<PaginationSample
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={searchQuery.length > 0 ? FilteredData.length : UsersData.length}
          onPageClick={handlePageClick}
        />}
 </div>
 <EditUserModal Show={ShowEditModal} Close={CloseModal} userDetails={UserDetails} ToggleFinished={ToggleFinished}/>
 </>
  )
}

export default UsersList