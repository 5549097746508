import React, { useState, useEffect } from 'react';
import '../Css/IssueQRpage.css';
import { BaseUrl } from '../Constants/BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from 'react-router-dom';
import NotifictaionModal from '../components/NotifictaionModal'
import { Network } from '@capacitor/network';
function IssueQRpage() {
  const navigate=useNavigate()
  const ApiToken = JSON.parse(localStorage.getItem('ChungathToken'));
  const offlineData = JSON.parse(localStorage.getItem('offlineData')) || [];
  const [CustomerName, setCustomerName] = useState('');
  const [CustomerNumber, setCustomerNumber] = useState('');
  const [HouseName, setHouseName] = useState('')
  const [Location, setLocation] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  function LogoutButton() {
    const confirmMessage = "Are you sure you want to logout?" 
    if (window.confirm(confirmMessage)) {
    localStorage.removeItem('ChungathToken');
    localStorage.removeItem('UserType');
    navigate('/')
    }
  }
const logout =()=>{
  localStorage.removeItem('ChungathToken');
    localStorage.removeItem('UserType');
  navigate('/')
}

async function CheckInternetStatus() {
  try {
    const status = await Network.getStatus();
    return status.connected;
  } catch (error) {
    console.error('Error checking network status:', error);
    return false; // Assuming no internet connection in case of an error
  }
}

const sendQR = async () => {
  const isConnected = await CheckInternetStatus();
console.log(isConnected)
  if (isConnected) {
    const IssueData = { Name: CustomerName, Mobile: CustomerNumber, CouponId: 1, HouseName, Location };
    // console.log(IssueData)
    if (CustomerNumber.length === 10) {
      setIsLoading(true);
      axios
        .post(BaseUrl + `/Issuer/IssueCoupon`, IssueData, {
          headers: {
            Authorization: `Bearer ${ApiToken}`,
          },
        })
        .then((response) => {
          // console.log(response.data);
          if (response.data.Status === 200) {
            toast.success(response.data.Message, {
              position: 'top-center',
            });
            Clear();
          } else {
            alert(response.data.Message);
          }
        })
        .catch(function (error) {
          toast.error('API token is not valid. Please log in again.', {
            position: 'top-center',
          });
          logout();
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (CustomerNumber.length === 0) {
        alert('Please Enter Customer WhatsApp number');
      } else if (CustomerNumber.length < 10) {
        alert('Customer WhatsApp number should be at least 10 digits long');
      } else if (CustomerNumber.length > 10) {
        alert('Customer WhatsApp number should not exceed 10 digits');
      }
    }
  } else {
    // Store data in local storage when offline
    const IssueData = { Name: CustomerName, Mobile: CustomerNumber, CouponId: 1, HouseName, Location };
    const offlineData = JSON.parse(localStorage.getItem('offlineData')) || [];
    offlineData.push(IssueData); // Add the data to the array
    localStorage.setItem('offlineData', JSON.stringify(offlineData));
    toast.info('Form data stored for later submission when online.');
    Clear();
  }
};


  const Clear=()=>{
    setCustomerName('')
    setCustomerNumber('')
    setHouseName('')
    setLocation('')
  }

  //Logined executive details
  const [ExcutiveName, setExcutiveName] = useState('')
 useEffect(() => {
  axios.get(BaseUrl + `/User/GetUserDetails`, {
    headers: {
      Authorization: `Bearer ${ApiToken}`,
    }
  })
  .then(response => {
    // console.log(response.data)
    setExcutiveName(response.data.UserName)
  }).catch(function (error) {
      toast.error('API token is not valid. Please log in again.', {
        position: 'top-center',
      })
      logout()
    console.log(error)
  })
 }, [])
 //Modal
 const [showNotificationModal, setShowNotificationModal] = useState(false);
 const handleClose =()=>{
  setShowNotificationModal(false)
 }
// Refresh
const [startY, setStartY] = useState(0);
const [refreshThreshold, setRefreshThreshold] = useState(300);
const [isRefreshing, setIsRefreshing] = useState(false);

useEffect(() => {
  const handleTouchStart = (event) => {
    setStartY(event.touches[0].clientY);
  };

  const handleTouchEnd = (event) => {
    const deltaY = event.changedTouches[0].clientY - startY;
    if (deltaY > refreshThreshold) {
      // Ask for confirmation before refreshing
      const shouldRefresh = window.confirm("Do you want to refresh?");
      if (shouldRefresh) {
        setIsRefreshing(true);
        window.location.reload();
      }
    }
  };

  document.addEventListener('touchstart', handleTouchStart);
  document.addEventListener('touchend', handleTouchEnd);

  return () => {
    document.removeEventListener('touchstart', handleTouchStart);
    document.removeEventListener('touchend', handleTouchEnd);
  };
}, [refreshThreshold, startY]);
  return (
    <>
    <NotifictaionModal Show={showNotificationModal} handleClose={handleClose} logout={logout}/>
    <div className="login-box">
      <div className="logout-button" style={{ position: 'absolute', top: 0, right: 0 }}>
  <button style={{ color: '#dc1818',background:'#282c34'}} onClick={LogoutButton}>
    <i class="fa-solid fa-right-from-bracket" style={{ marginRight: '8px' }}></i>   
  </button>
</div>
{offlineData.length>0 && navigator.onLine && (
  <div className="notification-bell active" style={{ position: 'absolute', top: 0, right: '40px' }}>
    <button style={{ color: '#d2ac67', background: '#282c34' }} onClick={() => setShowNotificationModal(true)}>
      <i className="fa-solid fa-bell" style={{ marginRight: '8px' }}></i>
    </button>
  </div>
)}
      <h2>Issue QR</h2>
      <div className="user-box">
        <input type="text" value={ExcutiveName}/>
        <label>Executive name</label>
      </div>
      <div className="user-box">
        <input
          type="number"
          name=""
          placeholder="Enter customer whatsapp number"
          required=""
          className="no-spinners"
          value={CustomerNumber}
          onChange={(e) => setCustomerNumber(e.target.value)}
        />
        <label>Phone</label>
      </div>
      <div className="user-box">
        <input
          type="text"
          name=""
          placeholder="Enter customer name"
          required=""
          value={CustomerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />
        <label>Name</label>
      </div>
      <div className="user-box">
        <input
          type="text"
          name=""
          placeholder="Enter customer house name"
          required=""
          value={HouseName}
          onChange={(e) => setHouseName(e.target.value)}
        />
        <label>House Name</label>
      </div>
      <div className="user-box">
        <input
          type="text"
          name=""
          placeholder="Enter customer location"
          required=""
          value={Location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <label>Location</label>
      </div>
      <div className="row d-flex">
        <div className="col-4">
          <button className="ClearButton" onClick={Clear}>
            <i className="fa-solid fa-arrows-rotate" style={{ marginRight: '8px' }}></i>
            Clear
          </button>
        </div>
        <div className="col-8">
          <button onClick={sendQR} disabled={isLoading}>
            <i
              className="fa-sharp fa-solid fa-qrcode"
              style={{ marginRight: '8px' }}
            ></i>
            {isLoading ? 'Sending QR...' : 'Send QR'}
          </button>
        </div>
      </div>
    </div>
    </>
  );
}

export default IssueQRpage;
